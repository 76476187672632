<template>
    <div class="container">
        <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="供给信息" name="first"></el-tab-pane>
                <el-tab-pane label="需求信息" name="second"></el-tab-pane>
            </el-tabs>
            <el-button v-if="activeName == 'first'" type="primary" class="more" icon="el-icon-plus" @click="addRow"
                >发布供给</el-button
            >
            <el-button v-else type="primary" class="more" icon="el-icon-plus" @click="addRow">需求供给</el-button>
        </div>
        <div class="box-tab">
            <router-link
                class="tab-item"
                :class="{ active: item.path === active }"
                v-for="(item, index) in tabs"
                :key="index"
                :to="item.path"
                replace
            >
                {{ item.name }}
            </router-link>
        </div>

        <router-view ref="list" class="list" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import menu from '../../../mixins/menu';
export default {
    name: 'publish',
    mixins: [menu],
    computed: {
        ...mapState(['userInfo']),
        tabs() {
            if (this.activeName === 'first') {
                return this.supplys;
            } else {
                return this.demands;
            }
        }
    },
    watch: {
        tabs() {
            this.menus = [...this.tabs];
            this.findActive();
        }
    },
    data() {
        return {
            activeName: 'first',
            newsList: [],
            list: [],
            currentPage1: 5,
            currentPage2: 5,
            currentPage3: 5,
            currentPage4: 4,
            supplys: [],
            demands: [],
            keeps: []
        };
    },
    methods: {
        chageFn(item) {
            console.log(item);
        },
        addRow() {
            this.$refs.list.addRow();
            // this.checkLogin(true).then(() => {
            //     this.$refs.list.addRow();
            // });
        },
        handleClick(info) {
            let path = '';
            if (info.name === 'first') {
                path = '/publish/patent?caseType=GENERAL';
            } else {
                path = '/publish/patentDemand?caseType=GENERAL';
            }
            this.$router.replace({
                path: path
            });
        }
    },
    created() {
        if (this.$route.query.tabName) {
            this.activeName = this.$route.query.tabName;
        }
        this.$http.post('/navigation/supply').then(res => {
            this.supplys = res.map(item => {
                return {
                    ...item,
                    path: `/publish${item.path}`
                };
            });
            let info = this.supplys.find(item => {
                return item.path === this.$route.fullPath;
            });
            if (info) {
                this.activeName = 'first';
            } else {
                this.activeName = 'second';
            }
        });
        this.$http.post('/navigation/demand').then(res => {
            this.demands = res.map(item => {
                return {
                    ...item,
                    path: `/publish${item.path}`
                };
            });
        });
    }
};
</script>

<style lang="less" scoped>
.container {
    // margin: 17px 20px 0;
    padding: 20px 0;
    .flex-col();
    flex-grow: 1;
    .tab {
        padding-left: 20px;
        position: relative;
        flex-shrink: 0;
        /deep/.el-tabs__nav-wrap {
            &::after {
                height: 1px;
                background-color: @bg;
            }
            .el-tabs__active-bar {
                height: 1px;
                background: #01A041;
            }
            .el-tabs__item {
                font-size: 16px;
            }
        }
        /deep/.el-tabs__header {
            margin: 0 0 0;
        }
        .more {
            position: absolute;
            right: 16px;
            top: -6px;
            z-index: 20;
        }
    }
    .box {
        display: flex;
        margin-top: 35px;
        border-bottom: 1px solid @bg;
        border-top: 1px solid @bg;
        p {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
            margin: 14px 0 10px;
        }
        .con {
            .flex();
            .con-all {
                .flex();
                width: 756px;
                flex-wrap: wrap;
                .all {
                    font-size: 13px;
                    font-weight: 400;
                    color: #939599;
                    line-height: 22px;
                    margin-right: 40px;
                }
            }
            .on {
                width: 89px;
                border-radius: 2px;
                border: 1px solid #ff8700;
                color: #ff8700;
                font-size: 13px;
                line-height: 26px;
                text-align: center;
                margin-right: 12px;
            }
            .edit {
                width: 56px;
                text-align: center;
                border-radius: 2px;
                font-size: 13px;
                color: #626366;
                line-height: 26px;
                border: 1px solid #c8c9cc;
            }
        }
        .text1 {
            font-size: 14px;
            font-weight: 400;
            color: #939599;
            line-height: 20px;
            margin-top: 14px;
        }
    }
}

.list {
    flex-grow: 1;
}
/deep/ .el-tabs__item {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
}
/deep/ .el-avatar {
    margin: 10px 15px 10px 0;
}
/deep/ .el-pagination {
    margin-top: 50px;
    text-align: center;
}
/deep/ .el-button {
    width: 130px;
    border-radius: 4px;
}

.news-img {
    // float: left;
    width: 223px;
    margin: 30px 16px 0 0;
    &:nth-child(4n) {
        margin-right: 0 !important;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; /* for IE */
    }
    .val {
        width: 223px;
        font-size: 12px;
        font-weight: 400;
        color: #939599;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; /* for IE */
    }
    .btn {
        width: 56px;
        height: 26px;
        border-radius: 2px;
        color: #626366;
        font-size: 13px;
        text-align: center;
        line-height: 26px;
        border: 1px solid #c8c9cc;
        margin-top: 15px;
    }
}
.demonstration {
    opacity: 0;
}
.box-tab {
    .flex();
    flex-shrink: 0;
    padding: 0 20px 20px;
    .tab-item {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        padding: 18px 21px 0 0;
        cursor: pointer;
        &.active {
            color: #01A041;
        }
    }
}
/deep/ .el-tabs__item.is-active{
    color: #01A041;
    &:hover{
        color: #01A041;
    }
} 
/deep/ .empty{
    top: 50% !important;
}
</style>
